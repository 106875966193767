import React from 'react';
import Title from '../../molecules/title/title';
import {
  getCommonOrganismData,
  getMegaByteFromByte,
  getExtensionFromMimeType,
} from '../../utils/functions';
import { Wrapper } from '../../utils/components';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';
import './LinkDocumentList.scss';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import { ButtonWithLink } from '../button/Button';
import ExternalLink from '../../atoms/static-icons/external-link';
import DownloadArrow from '../../atoms/static-icons/download-arrow';
import { ResourceWrapper } from './helpers';

function LinkDocumentList({ data, considerMenuSpace }) {
  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const listWidth = data?.listWidth?.value[0];
  const resources = data?.resource;

  return (
    <Wrapper
      data-module-code='C-90'
      data-module-name='link-document-list'
      tag={htmlTagWrapper}
      className={classNames(`link-document-list ${marginTop}`, {
        'link-document-list--colored-background':
          backgroundColor !== 'transparent',
      })}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      <div
        className={classNames('link-document-list wrapper', {
          'wrapper--underflow': listWidth === 'small',
        })}
      >
        {resources &&
          resources.map((resource, i) => (
            <ResourceWrapper
              key={resource?.content?.document?.node?.contentUrl}
              resource={resource}
            >
              <section
                className={classNames('link-document-list__resource', {
                  'link-document-list__resource--under':
                    resource?.content?.document?.node?.contentUrl &&
                    !resource?.content?.link?.value,
                })}
                style={{
                  '--bg-resource': `var(--${resource?.content?.bgColorResource?.value[0]})`,
                }}
              >
                <div
                  className={classNames('link-document-list__body', {
                    'link-document-list__body--link':
                      resource?.content?.link?.value,
                  })}
                >
                  {resource?.content?.document?.node?.contentUrl &&
                    resource?.content?.document?.content?.showDateDocument
                      ?.value &&
                    !resource?.content?.link?.value && (
                      <span className='link-document-list__date'>
                        {resource?.content?.document?.node?.dateModified}
                      </span>
                    )}
                  {resource?.content?.resourceTitle?.value && (
                    <span className='link-document-list__title'>
                      {resource.content.resourceTitle.value}
                    </span>
                  )}
                </div>
                {resource?.content?.link?.value && (
                  <ButtonWithLink
                    type='icon'
                    icon={ExternalLink}
                    href={resource.content.link.value}
                    className='link-document-list__link-button'
                  ></ButtonWithLink>
                )}
                {resource?.content?.document?.node?.contentUrl &&
                  !resource?.content?.link?.value && (
                    <div
                      className={classNames('link-document-list__info', {
                        'link-document-list__info--under':
                          resource?.content?.document?.node?.contentUrl &&
                          !resource?.content?.link?.value,
                      })}
                    >
                      {resource?.content?.document?.content
                        ?.showExtensionDocument?.value && (
                        <span className='link-document-list__extension'>
                          {getExtensionFromMimeType(
                            resource?.content?.document?.node?.encodingFormat
                          )}
                        </span>
                      )}
                      {resource?.content?.document?.content?.showSizeDocument
                        ?.value && (
                        <span
                          className={classNames('link-document-list__size', {
                            'link-document-list__size--with-margin':
                              resource?.content?.document?.content
                                ?.showExtensionDocument?.value,
                          })}
                        >
                          {'(' +
                            getMegaByteFromByte(
                              resource?.content?.document?.node?.sizeInBytes
                            ) +
                            ')'}
                        </span>
                      )}
                      {resource?.content?.document?.content?.documentMode
                        ?.value[0] === 'tab' ? (
                        <a
                          href={resource?.content?.document?.node?.contentUrl}
                          target='_blank'
                          rel='noreferrer'
                          aria-label={resource?.content?.document?.node?.title}
                          className={classNames(
                            'link-document-list__document-button',
                            {
                              'link-document-list__document-button--with-margin':
                                resource?.content?.document?.content
                                  ?.showExtensionDocument?.value ||
                                resource?.content?.document?.content
                                  ?.showSizeDocument?.value,
                            }
                          )}
                        >
                          <ExternalLink />
                        </a>
                      ) : (
                        <a
                          href={resource?.content?.document?.node?.contentUrl}
                          download
                          aria-label={resource?.content?.document?.node?.title}
                          className={classNames(
                            'link-document-list__document-button',
                            {
                              'link-document-list__document-button--with-margin':
                                resource?.content?.document?.content
                                  ?.showExtensionDocument?.value ||
                                resource?.content?.document?.content
                                  ?.showSizeDocument?.value,
                            }
                          )}
                        >
                          <DownloadArrow />
                        </a>
                      )}
                    </div>
                  )}
              </section>
            </ResourceWrapper>
          ))}
      </div>
    </Wrapper>
  );
}

export default withPreview(LinkDocumentList);
