import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Icon from '../icon';

const DownloadArrow = ({ className, ...other }) => {
  const { iconSvg } = useStaticQuery(graphql`
    {
      iconSvg(icon: "download-arrow")
    }
  `);
  return (
    <Icon className={className} icon={iconSvg} aria-hidden={true} {...other} />
  );
};

export default DownloadArrow;
