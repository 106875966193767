import React from 'react';
export const ResourceWrapper = ({ children, resource }) => {
  if (resource?.content?.link?.value)
    return (
      <a
        href={resource?.content?.link?.value}
        rel='nopener noreferrer'
        target='_blank'
      >
        {children}
      </a>
    );
  return resource?.content?.document?.content?.documentMode?.value[0] ===
    'tab' ? (
    <a
      href={resource?.content?.document?.node?.contentUrl}
      target='_blank'
      rel='noreferrer'
      aria-label={resource?.content?.document?.node?.title}
    >
      {children}
    </a>
  ) : (
    <a
      href={resource?.content?.document?.node?.contentUrl}
      download
      aria-label={resource?.content?.document?.node?.title}
    >
      {children}
    </a>
  );
};
